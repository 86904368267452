@use "./Mixins";

.addEditSidebarItem {
  @include Mixins.flex(column, flex-start, flex-start);
  min-height: 30px;
  &.doubleItem {
    @include Mixins.flex(row, flex-start, flex-start);
  }
  &.categoryGrid {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 25px;
    background-color: var(--MainBackground);
    box-shadow: 0 5px 10px 0 var(--ShadowColor);
    padding-left: 20px;
    padding-right: 20px;
    margin: 5px;

    .addEditSidebarItemInput {
      font-weight: 400;
    }
  }
}

.addEditSidebarDoubleItem {
  @include Mixins.flex(column, flex-start, flex-start);
}

.addEditSidebarItemTextContainer {
  @include Mixins.flex(column, flex-start, flex-start);
}

.addEditSidebarItemText {
  font-size: 1.25em;
  color: var(--FontColor);
  font-weight: 700;
  margin-right: 10px;
  &.subText {
    font-size: 0.875em;
    font-weight: 500;
  }
}

.addEditSidebarItemInput {
  font-size: 1em;
  font-weight: 700;
  color: var(--InputFontColor);
  &.textValueBoxes {
    width: 250px;
    border-radius: 3px;
    background-color: var(--InputBackground);
    &.summary {
      height: 150px;
      resize: none;
    }
  }
  &.categories {
    color: var(--FontColor);
  }
}

.addEditCheckbox {
  width: auto;
  padding: 0;
  margin-right: 5px;
}

.addEditButtonsContainer {
  @include Mixins.flex(row, center, center);
  margin-bottom: 10px;
}

.adminArticleButton {
  @include Mixins.flex(row, center, center);
  background-color: var(--FunkerRed);
  color: var(--HeaderFooterText);
  padding: 4px;
  font-size: 1.25em;
  font-weight: 700;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
  &.deleteArticle {
    position: absolute;
    right: 0;
    top: 10px;
  }
}
