@use "./Mixins";

//Main Header Styles
.headerContainer {
  background-color: var(--HeaderFooterBackground);
  width: 100%;
  height: 63px;
  display: grid;
  grid-auto-flow: column;
  @media (max-width: 1258px) {
    grid-template-columns: auto;
    width: 100%;
    padding: 0px;
  }
  padding: 0 10px;
}

.headerLeft {
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  @media (max-width: 1258px) {
    grid-template-columns: auto auto;
  }
}

.headerRight {
  display: grid;
  grid-auto-flow: column;
  width: fit-content;
  justify-self: flex-end;
}

.headerItem {
  margin-left: 5px;
  min-width: 63px;
  @include Mixins.flexCenterStart(row);
  &.icon {
    @include Mixins.flexCenterEnd(row);
    margin-right: 0px;
  }
  @media (max-width: 1258px) {
    margin-left: 0;
  }
}

.headerIcon {
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  &.bars {
    color: var(--HeaderFooterText);
    font-size: 1.4375em;
    padding-right: 20px;
    &:hover {
      color: var(--FunkerRed);
    }
  }
  &.list {
    font-size: 0.75em;
    padding-right: 5px;
  }
  &.caretDown {
    font-size: 0.625em;
    margin-left: 5px;
  }
  &.chartLineUp {
    font-size: 0.875em;
    padding-right: 5px;
  }
  &.clock {
    font-size: 0.875em;
    padding-right: 5px;
    color: var(--HeaderFooterText);
    @media (max-width: 1258px) {
      margin-right: 5px;
    }
  }
  &.search {
    padding: 0;
    font-size: 0.9375em;
  }
  &.pro {
    font-size: 0.875em;
    padding-right: 5px;
    color: var(--FunkerProGold);
  }
}

.headerLogo {
  height: 50px;
  max-width: 100%;
  cursor: pointer;
  object-fit: contain;
  @media (max-width: 1258px) {
    height: 40px;
  }
}

.headerItemContainer {
  @include Mixins.flexCenterStart(row);
  font-size: 0.875em;
  font-weight: 700;
  color: var(--HeaderFooterText);
  text-decoration: none;
  cursor: pointer;
  &.search {
    @include Mixins.flexCenterEnd(row);
    &:hover {
      color: var(--HeaderFooterText);
    }
    padding: 15px 10px;
    height: 100%;
  }
  &.avatar {
    @include Mixins.flexCenter(row);

    svg {
      height: 20px;
    }
  }
}

.headerItemContainerItem {
  @include Mixins.flex(row, flex-start, center);
  margin-left: 10px;
  font-size: 0.875em;
  font-weight: 700;
  text-decoration: none;
  &.search {
    @include Mixins.flexCenter(row);
  }
  &.avatar {
    @include Mixins.flexCenter(row);
    height: 35px;
    width: 35px;
    margin-left: 0px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.headerItemText {
  &:hover {
    color: var(--FunkerRed);
  }
}

.searchInput {
  height: 100%;
  padding: 0;
  border-image-width: 0 !important;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border: none;
  outline: 0;
  color: black;
}

.searchIconContainer {
  @include Mixins.flexCenter(row);
  background-color: var(--FunkerRed);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  height: 100%;
  width: 32px;
}

.avatarImage {
  height: 100%;
  width: 100%;
}
