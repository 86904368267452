@use "./Mixins";

.headerSidebarContainer {
  position: fixed;
  height: 100%;
  width: 250px;
  background-color: var(--HeaderSidebarBackground);
  animation: headerSidebarAnimation 0.3s ease-in-out;
  z-index: 100;
  &.slidingOut {
    left: -250px;
    animation: headerSidebarAnimationReverse 0.3s ease-in-out;
  }
}

@keyframes headerSidebarAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes headerSidebarAnimationReverse {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.headerSidebarHeaderTop {
  height: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
}

.headerSidebarHeaderBottom {
  @include Mixins.flex(row, flex-start, center);
  padding: 0 20px;
}

.headerSidebarItem {
  @include Mixins.flex(row, flex-start, flex-end);
  width: 100%;
  &.icon {
    @include Mixins.flex(row, flex-end, flex-end);
  }
  &.links {
    @include Mixins.flex(row, flex-start, center);
    cursor: pointer;
    height: 36px;
    color: var(--FontColorDarker);
    &:hover {
      background-color: var(--FunkerRed);
      color: var(--SectionBackground);
    }
  }
  &.categories {
    @include Mixins.flex(column, flex-start, flex-start);
  }
}

.headerSidebarText {
  font-size: 0.875em;
  font-weight: 700;
  user-select: none;
}

.headerSidebarIcon {
  font-size: 1.375em;
  color: var(--HeaderSidebarX);
  &:hover {
    color: var(--FunkerRed);
  }
  &.links {
    font-size: 0.875em;
    color: inherit;
    margin-right: 10px;
    margin-left: 20px;
    &.pro {
      color: var(--FunkerProGold);
    }
  }
}

.headerSidebarLogo {
  width: 75px;
  cursor: pointer;
}

.headerSidebarContent {
  @include Mixins.flex(column, flex-start, flex-start);
  height: 100%;
  width: 100%;
  padding: 20px 0;
}
