@use "./Mixins";

.footerContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto 1fr;
  background-color: var(--HeaderFooterBackground);
  align-self: flex-end;
  width: 100%;
  height: fit-content;
  min-height: 63px;
  @media (max-width: 1285px) {
    grid-auto-flow: row;
    grid-template-columns: auto;
  }
}

.footerItemContainer {
  @include Mixins.flex(column, center, center);
  &.middle {
    display: grid;
    grid-auto-flow: row;
  }
}

.footerItem {
  @include Mixins.flex(row, center, center);
}

.footerItemLable {
  text-align: center;
  font-size: 0.875em;
  margin: 0 5px;
  font-weight: 700;
  color: var(--HeaderFooterText);
  &.middle {
    &:hover {
      cursor: pointer;
      color: var(--FunkerRed);
    }
  }
  &.sponsor {
    cursor: default;
    user-select: none;
  }
}

.footerItemLableText {
  font-size: 1em;
  font-weight: 500;
  cursor: default;
  user-select: none;
  color: var(--HeaderFooterText);
  &.funker {
    color: var(--FunkerRed);
  }
  @media (max-width: 1285px) {
    font-size: 0.75em;
  }
}

.footerItemLogos {
  @include Mixins.flex(row, center, center);
  margin: 0px 15px;
}

.footerLogo {
  width: 35px;
  @media (max-width: 1285px) {
    width: 25px;
  }
  &:hover {
    cursor: pointer;
  }
}

.footerItemIconContainer {
  color: var(--HeaderFooterText);
  &.copyRight {
    margin-right: 5px;
  }
  &.social {
    font-size: 1.375em;
    margin-right: 10px;
    :hover {
      cursor: pointer;
      color: var(--FunkerRed);
    }
    @media (max-width: 1285px) {
      font-size: 1em;
    }
  }
}
