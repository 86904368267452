@use "./Mixins";

.editArticleButtonContainer {
  position: fixed;
  @include Mixins.flex(row, center, center);
  height: 50px;
  width: 50px;
  bottom: 90px;
  right: 150px;
  z-index: 100;
  border: 1px solid var(--FontColorDarker);
  border-radius: 50%;
  background-color: var(--SectionBackground);
  box-shadow: 2px 2px 5px 0px var(--FunkerGrey);
  padding: 10px;
  &:hover {
    background-color: var(--FunkerRed);
  }
}

.editArticleButton {
  color: var(--FontColorDarker);
  font-weight: 700;
  font-size: 1.125em;
}

.articleTableOfContents {
  clear: left;
  position: relative;
  float: left;
  background-color: var(--MainBackground);
  width: min(25em, 100%);
  height: min-content;
  max-width: 100%;
  padding: 20px;
  color: var(--FunkerRed);
  box-shadow: 2px 2px 5px 0px var(--FunkerGrey);
  margin-right: 10px;
  margin-bottom: 10px;

  .articleTableOfContentsTitle {
    font-size: 1.125em;
    font-weight: 700;
    cursor: pointer;
  }
  .articleTableOfContentsArticleTitle {
    margin-top: 10px;
    margin-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
  .articleTableOfContentsItems {
    margin-top: 10px;
    margin-left: 5px;
    text-decoration: underline;
    font-size: 0.875em;
    cursor: pointer;
    .tableOfContentsItem {
      margin-top: 5px;
      margin-left: 10px;
    }
  }
}

.scriptTagWrapper {
  width: 100%;
}
