@use "./Mixins";

.featuredArticleImage{
  padding-right: 10px;
  width: 40%;
  @media(max-width: 1285px){
    width: 100%;
    padding-right: 0;
  }
  height: auto;
  // background-color: black;
  float: left;
  img{
    width: 100%;
  }
}