@mixin fullSize {
  width: 100%;
  height: 100%;
}

@mixin flex($direction, $justify, $align) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}

@mixin flexCenter($direction) {
  display: flex;
  flex-direction: $direction;
  justify-content: center;
  align-items: center;
}

@mixin flexCenterStart($direction) {
  display: flex;
  flex-direction: $direction;
  justify-content: flex-start;
  align-items: center;
}

@mixin flexCenterEnd($direction) {
  display: flex;
  flex-direction: $direction;
  justify-content: flex-end;
  align-items: center;
}