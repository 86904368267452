@use "./Mixins";

.filterContainer {
  display: grid;
  grid-template-columns: auto auto auto auto;
  column-gap: 5px;
  @media (max-width: 1285px) {
    column-gap: 3px;
    padding-bottom: 2px;
  }
}

.filterItem {
  @include Mixins.flex(row, center, center);
  height: 100%;
  border-right: 1px solid var(--SectionBackground);
  margin: 1px;
  cursor: pointer;
}

.filterSelect {
  height: 100%;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9375em;
}

.filterLabel {
  width: 80px;
  font-weight: 600;
  color: var(--FontColorDarker);
  @media (max-width: 1285px) {
    display: none;
  }
}
