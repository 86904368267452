body {
  --HeaderFooterBackground: #0a0a0a;
  --FunkerRed: #ad2025;
  --FunkerGrey: #8a8a8a;
  --FunkerLightGrey: #ededed;
  --FunkerDarkGrey: #6a6a6a;
  --MainBackground: #f2f2f2;
  --SectionBackground: #ffffff;
  --FontColor: #222222;
  --FontColorDarker: #000000;
  --FontColorAlwaysDark: #222222;
  --HeaderFooterText: #efefef;
  --FunkerProGold: #ffd700;
  --DropdownBorder: #cacaca;
  --MobileSelectedBlue: #3071c5e6;
  --HeaderSidebarBackground: #ffffff;
  --HeaderSidebarX: (var(--FunkerGrey));
  --ShadowColor: rgba(100, 100, 100, 0.5);
  --HeaderDropdownBackground: var(--FontColor);
  --InputBackground: var(--SectionBackground);
  --InputFontColor: var(--FontColor);
  --DropdownBackground: var(--HeaderSidebarBackground);
}

[data-funkerTheme="dark"] {
  --HeaderFooterBackground: #0a0a0a;
  --FunkerRed: #ad2025;
  --FunkerGrey: #8a8a8a;
  --FunkerLightGrey: #000000;
  --FunkerDarkGrey: #5a5a5a;
  --MainBackground: #000000;
  --SectionBackground: #0a0a0a;
  --FontColor: #f2f2f2;
  --FontColorDarker: #ffffff;
  --HeaderFooterText: #efefef;
  --FunkerProGold: #ffd700;
  --DropdownBorder: #cacaca;
  --MobileSelectedBlue: #3071c5e6;
  --HeaderSidebarBackground: #8a8a8a;
  --HeaderSidebarX: var(--FunkerDarkGrey);
  --ShadowColor: rgba(75, 75, 75, 0.5);
  --HeaderDropdownBackground1: #222222;
  --HeaderDropdownBackground2: var(--FunkerLightGrey);
  --InputBackground: var(--FontColor);
  --InputFontColor: var(--HeaderDropdownBackground1);
  --DropdownBackground: var(--HeaderSidebarBackground);
}
