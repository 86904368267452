@use "./Mixins";

//Main Screen Content Styles
.contentSidebarContainer {
  @include Mixins.flex(column, flex-start, center);
  width: 100%;
  position: relative;
  &.notSelected {
    @media (max-width: 1285px) {
      display: none;
    }
  }
}

.contentSidebar {
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  max-width: 100%;
  display: grid;
  background-color: var(--SectionBackground);
  box-shadow: 1px 3px 3px 0px var(--ShadowColor);
  row-gap: 10px;
  &.article {
    grid-template-rows: min-content auto min-content;
    height: 100%;
  }
  &.addEdit {
    grid-auto-flow: row;
  }
}

.mainScreenContentContainer {
  justify-self: center;
  width: min(100%, 1700px);
  height: 100%;
  // overflow-y: auto;
  // overflow-x: hidden;
  position: relative;
}

.mainContentContainer {
  @include Mixins.flex(column, flex-start, flex-start);
  padding: 10px 20px;
  width: 100%;
}

.mainContent {
  display: grid;
  height: fit-content;
  min-height: 1vh;
  width: 100%;
  &.featured {
    grid-template-rows: auto auto;
    row-gap: 20px;
  }
  &.articleSection {
    grid-template-columns: auto 25%;
    column-gap: 20px;

    .contentSidebarContainer {
      position: sticky;
      top: 10px;
      height: min-content;
    }
    @media (max-width: 1285px) {
      grid-auto-flow: row;
      grid-template-columns: auto;
    }
  }
  &.addEdit {
    grid-template-columns: auto 25%;
  }
  &.article {
    grid-template-columns: auto 25%;
    justify-items: center;
    column-gap: 20px;
    &.addEdit {
      min-height: calc(100vh - 160px);
    }
    @media (max-width: 1285px) {
      grid-auto-flow: row;
      grid-template-columns: auto;
    }
  }
}

.mobileTabs {
  display: none;

  @media (max-width: 1285px) {
    display: grid;
    grid-template-columns: auto auto;
    justify-items: center;
    column-gap: 10px;
    width: 100%;
    background-color: var(--SectionBackground);
    box-shadow: 0 5px 10px 0 var(--ShadowColor);
    margin-bottom: 10px;
    outline: 1px solid var(--FunkerLightGrey);
  }
}

.mobileTab {
  @include Mixins.flex(row, center, center);
  padding: 10px;
  width: 50%;
  color: var(--FunkerGrey);
  cursor: pointer;
  &.selected {
    color: var(--MobileSelectedBlue);
    border-bottom: 3px solid var(--MobileSelectedBlue);
    font-size: 0.9375em;
  }
}

.tabsIcon {
  width: 14px;
  height: 14px;
  margin-right: 2px;
  margin-bottom: 2px;
  &.comments {
    height: 18px;
    width: 18px;
    margin-bottom: 3px;
  }
}

.mainContentFeaturedContent {
  width: 100%;
  display: grid;
  grid-template-columns: 0.8fr 0.2fr;
  column-gap: 10px;
  @media (max-width: 1285px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    row-gap: 20px;
    column-gap: 0;
  }
  justify-items: center;
}

.mainContentSections {
  &.article {
    width: 100%;
  }
}

.mainContentBox {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: 0 5px 10px 0 var(--ShadowColor);
  background-color: var(--SectionBackground);
  &.mostRecent {
    height: fit-content;
    width: 100%;
    padding: 0;
    justify-self: center;
  }
  &.addEdit {
    width: 100%;
    display: grid;
    grid-template-rows: min-content auto;
    row-gap: 10px;
  }
  &.articleSection {
    width: 100%;
  }
  &.article {
    padding: 0;
    display: grid;
    grid-template-rows: min-content auto;
    height: 100%;
  }
  &.featured {
    display: grid;
    grid-template-rows: auto auto auto;
    row-gap: 10px;
  }
}

.mainContentHeader {
  height: fit-content;
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  &.mostRecent {
    padding: 10px 10px 0 10px;
  }
  &.featured {
    justify-items: center;
  }
  &.articleSection {
    row-gap: 10px;
  }
  &.article {
    height: auto;
    // padding: 10px 0;
    // grid-template-rows: 150px 35px;
  }
  &.addEditRow {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 20px;
    padding: 10px;
  }
  &.addEditColumn {
    @include Mixins.flex(column, center, center);
  }
}

.mainContentInputGroup {
  @include Mixins.flex(column, center, center);
  width: 100%;
  row-gap: 5px;
  &.button {
    @include Mixins.flex(row, center, center);
    gap: 5px;
  }
  .hidden {
    display: none;
  }
  .mainContentButton {
    @include Mixins.flex(row, center, center);
    width: 100%;
    height: 100%;
    padding: 2px;
    cursor: pointer;
    &:hover {
      background-color: var(--FunkerLightGrey);
    }
  }
}

.mainContentHeaderTopContainer {
  @include Mixins.flex(row, center, center);
  border-bottom: 1px solid var(--FunkerGrey);
  // padding-bottom: 10px;
  height: fit-content;
  cursor: default;
}

.mainContentHeaderBottomContainer {
  display: grid;
  grid-template-columns: 1.5fr 2fr 1.5fr;
  height: 100%;
  width: 100%;
  padding: 10px;

  .mainContentHeaderBottomItemGroup {
    @include Mixins.flex(row, center, center);
    height: 100%;
    width: 100%;
    gap: 10px;
  }
}

.mainContentHeaderBottomItem {
  cursor: default;
  @include Mixins.flex(row, center, center);
  &.article {
    height: 100%;
    width: 100%;
    border-bottom: 1px solid var(--FunkerGrey);
    @media (max-width: 1285px) {
      width: fit-content;
    }
  }
  &.cursorPointer {
    cursor: pointer;
  }

  &.shareButton {
    margin-left: auto;
    margin-right: 20px;
  }
}

.mainContentHeaderBottomItemIcon {
  margin-right: 5px;
  font-size: 1.25em;
  color: var(--FunkerGrey);
  &.red {
    color: var(--FunkerRed);
  }
  &.votes {
    cursor: pointer;
  }
  @media (max-width: 1285px) {
    font-size: 0.6em;
    &.votes {
      margin-right: 1.5px;
    }
  }
  &.calendar {
    margin-left: 5px;
  }
}

.mainContentHeaderBottomItemText {
  @include Mixins.flex(row, center, center);
  font-size: 1.25em;
  color: var(--FunkerGrey);
  font-weight: 700;
  margin-right: 10px;
  &.red {
    color: var(--FunkerRed);
  }
  .articleLink {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 1285px) {
    font-size: 0.6em;
    &.votes {
      margin-right: 5px;
      font-size: 0.4em;
    }
  }
}

.mainContentHeaderTitle {
  color: var(--FontColor);
  font-size: 2.1875em;
  font-weight: 700;
  padding: 0 5px;
  &.article {
    text-align: center;
    @media (max-width: 1285px) {
      font-size: 1.5em;
      padding: 0 2.5px;
    }
  }

  &.articleSection {
    @include Mixins.flex(row, flex-start, center);
    column-gap: 1em;
    @media (max-width: 1285px) {
      flex-direction: column;
      align-items: flex-start;
    }
    font-size: 2.1875em;
    color: var(--FunkerRed);

    .titleText {
      @media (max-width: 1285px) {
        width: 100%;
      }
    }

    .searchingFor {
      @include Mixins.flex(row, flex-start, center);
      column-gap: 0.2em;
      @media (max-width: 1285px) {
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
      font-size: 0.7em;
      color: var(--FunkerGrey);

      .searchingForLabel {
        display: grid;
        grid-template-columns: min-content auto;
        align-items: center;
        column-gap: 0.2em;
      }

      .searchingForValue {
        @media (max-width: 1285px) {
          text-align: right;
        }
      }

      svg {
        color: var(--FunkerRed);
        font-size: 0.75em;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }
  &.featured {
    font-size: 1.5625em;
    color: var(--FontColor);
  }
  &.addEdit {
    font-size: 1.5625em;
    color: var(--FontColor);
  }
  &.mostRecent {
    font-size: 1.5625em;
    color: var(--FontColor);
  }
}

.mainContentHeaderIcon {
  font-size: 1.4375em;
  color: var(--FunkerRed);
  margin-right: 5px;
}

.mainContentHeaderItem {
  @include Mixins.flex(row, flex-start, center);
  &.addEdit {
    font-size: 1.25em;
    color: var(--FunkerGrey);
  }
}

input {
  font-size: 1.25em;
  color: var(--FontColorAlwaysDark);
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.mainContentInput {
  border-radius: 3px;
  background-color: var(--InputBackground);
  font-size: inherit;
}

.mainContentBody {
  width: 100%;
  &.featured {
    background-color: var(--FunkerLightGrey);
    padding: 10px 20px;
  }
  &.articleSection {
    @include Mixins.flex(row, space-between, center);
    flex-wrap: wrap;
  }
  &.article {
    background-color: var(--FunkerLightGrey);
    padding: 0px 20px 20px 20px;
  }
  &.mostRecent {
    padding: 10px;
  }
}

.mainContentBodyItem {
  &.articleSection {
    display: grid;
    grid-template-rows: 17.5em 4em;
    border: 1px solid var(--FunkerGrey);
    margin: 10px auto;
    width: calc(50% - 20px);
    height: 21.5em;
    cursor: pointer;
    border-radius: 3px;
    &.hidden {
      background-color: var(--FunkerRed);
    }
    &.notPublished {
      background-color: var(--FunkerGrey);
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.mainContentBodyItemInfoContainer {
  @include Mixins.flex(column, flex-start, flex-start);
  max-width: 550px;
  padding: 5px;
}

.mainContentText {
  &.articleCard {
    font-size: 1em;
    font-weight: 700;
    color: var(--FontColor);
    word-wrap: break-word;
    &.categories {
      @include Mixins.flex(row, flex-start, center);
    }
    &.dateview {
      font-size: 0.9375em;
      font-weight: 500;
      color: var(--FunkerGrey);
      margin-right: 10px;
    }
  }
  &.article {
    font-size: 1.15em;
    font-weight: 400;
    color: var(--FontColorDarker);
  }
  &.addEdit {
    font-size: 1.15em;
    font-weight: 400;
    color: var(--FontColorDarker);
    height: 100%;
  }
  img {
    width: fit-content;
    height: fit-content;
    max-width: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
}

.mainContentImageContainer {
  &.article {
    width: fit-content;
    min-width: min(25em, 100%);
    overflow: hidden;
    float: left;
    margin-right: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
  }
}

.mainContentBodyItemImage {
  &.articleCard {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
  &.article {
    width: 100%;
    max-width: 550px;
  }
}

.mainContentBodyItemImageText {
  @include Mixins.flex(row, center, center);
  font-weight: 500;
  font-style: italic;
}

.mainContentBodyItemRow {
  @include Mixins.flex(row, flex-start, flex-start);
}

.icon {
  color: var(--FunkerGrey);
  &.eye {
    font-size: 0.9375em;
    margin-right: 5px;
  }
  &.circle {
    font-size: 0.3125em;
  }
}

.mainContentFooter {
  @include Mixins.flex(row, center, center);
  width: 100%;
  padding: 10px 0px;
  border-top: 1px solid var(--FunkerGrey);
  cursor: pointer;
  &.featured {
    border-top: none;
    padding: 0px;
  }
}

.mainContentFooterText {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--FunkerGrey);
  &.featured {
  }
}
