.mainScreenContainer {
  background-color: var(--MainBackground);
  display: grid;
  grid-auto-flow: row;
  grid-template-rows: min-content auto min-content;
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  font-size: min(calc(10px + 1vmin), 16px);
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 270px;
  height: 100%;
  color: var(--FontColor);
  font-size: 1.25em;
  font-weight: 700;
  animation: loadingFadeIn 4s ease-in-out;
}

.loadingAnimation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 8px;
  background-color: var(--FontColor);
}

.loadingAnimationInner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background-color: var(--MainBackground);
}

// @keyframes loadingAnimationFrames {
//   25% {
//     transform: translate(10px, 0px);
//   }
//   50% {
//     transform: translate(10px, 10px);
//   }
//   75% {
//     transform: translate(0px, 10px);
//   }
// }

@keyframes loadingFadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: .8;
  }
}
