@use "./Mixins";

.articleSidebarRow {
  @include Mixins.flex(row, flex-start, center);
  width: 100%;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
}

.articleSidebarRowItemContainer {
  display: grid;
  width: 100%;
  &.articles {
    grid-auto-rows: min-content;
    padding-left: 10px;
    align-self: flex-start;
    height: 100%;
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
}

.articleSidebarRowItem {
  @include Mixins.flex(column, center, flex-start);
  height: fit-content;
  width: 100%;
  // padding: 20px 0;
  &.title {
    @include Mixins.flex(row, flex-start, center);
    user-select: none;
    margin-bottom: 10px;
  }
  &.article {
    cursor: pointer;
    height: 90px;
  }
  img {
    width: 100%;
  }
}

.articleSidebarRowItemText {
  font-size: 1.0625em;
  font-weight: 700;
  color: var(--FontColorDarker);
  word-wrap: break-word;
  width: 100%;
  &.smallerText {
    font-size: 0.9em;
  }
  &.smallestText {
    font-size: 0.8em;
  }
  .image {
    height: 75px;
    min-height: 75px;
    max-height: 75px;
    margin-right: 5px;
    width: 120px;
    min-width: 120px;
    max-width: 120px;
    border-radius: 3px;
    object-fit: cover;
  }
  &.articleInfo {
    @include Mixins.flex(column, space-between, center);
    height: 100%;
    width: 100%;
    padding-bottom: 15px;
  }
  &.article {
    :hover {
      color: var(--FunkerRed);
    }
    height: 100%;
    @include Mixins.flex(row, flex-start, flex-start);
  }
  &.icon {
    margin-right: 3px;
    color: var(--FunkerRed);
    width: 30px;
  }
  &.secondRow {
    @include Mixins.flex(row, flex-start, center);
    font-size: 0.875em;
    font-weight: 700;
    color: var(--FunkerGrey);
    width: 100%;
    .icon {
      margin-right: 3px;
    }
    .views {
      margin-right: 5px;
    }
  }
}

.adContainer {
  width: 100%;
  height: 0;
  font-size: 0;
  &.banner {
    padding-bottom: 11.6%;
  }
  &.box {
    padding-bottom: 100%;
  }
  img {
    width: 100%;
  }
}

.sidebarBannerAd {
  width: 100%;
}

.sidebarBoxAd {
  width: 80%;
}

.articleSidebarImage {
  width: 100%;
  max-width: 350px;
  height: 100%;
  object-fit: cover;
}
