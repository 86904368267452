div {
  box-sizing: border-box;
}

.hiddenOnMobile {
  @media(max-width: 1258px) {
    display: none !important;
  }
}

.showOnMobile {
  display: none;

  @media(max-width: 1258px) {
    display: block !important;
  }
}