@use "./Mixins";

.mostRecentItemContainer {
  @include Mixins.flex(column, flex-start, flex-start);
  width: 100%;
  height: 100%;
  padding: 10px;
  border: 1px solid var(--FunkerGrey);
  margin-top: 12px;
}

.mostRecentItemTitle {
  font-size: 1.25em;
  font-weight: 700;
  color: var(--FontColor);
}

.mostRecentItemViews {
  font-size: 0.9375em;
  color: var(--FunkerGrey);
}

.mostRecentItemDate {
  font-size: 0.9375em;
  color: var(--FunkerGrey);
  margin-left: 10px;
}

.mostRecentItem {
  @include Mixins.flex(row, flex-start, center);
  width: 100%;
  margin-top: 5px;
}

.icon {
  margin-right: 5px;
  &.eye {
    color: var(--FunkerGrey);
  }
}
