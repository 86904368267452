@use "./Mixins";

.commentsContainer {
  row-gap: 10px;
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
  padding: 10px 20px;
  width: 100%;
  height: fit-content;
  box-shadow: 0 5px 10px 0 var(--ShadowColor);
  background-color: var(--SectionBackground);
  &.notSelected {
      @media(max-width: 1285px) {
        display: none;
      }
    }
}
