@use "./Mixins";

.dropdown {
  background-color: transparent;
  width: 400px;
  height: 400px;
  &.avatar {
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 100;
    color: var(--HeaderFooterText);
  }
  &.categories {
    position: absolute;
    height: 300px;
    width: 225px;
    left: 135px;
    top: 0px;
    z-index: 100;
    color: var(--HeaderFooterText);
  }
  &.sidebar {
    @include Mixins.flex(column, center, center);
    height: fit-content;
    width: 100%;
    color: var(--FontColorDarker);
  }
}

.dropdownContainer {
  &.avatar {
    position: absolute;
    top: 63px;
    right: 0px;
    padding: 15px 0px 5px 15px;
    width: 200px;
    background-color: var(--HeaderFooterBackground);
    color: var(--HeaderFooterText);
  }
  &.categories {
    position: absolute;
    padding-top: 3px;
    top: 63px;
    left: 18px;
    width: 175px;
    background-color: var(--HeaderFooterBackground);
    color: var(--HeaderFooterText);
    border: 1px solid var(--DropdownBorder);
  }
  &.sidebar {
    width: 190px;
    background-color: var(--DropdownBackground);
  }
}

.dropdownItem {
  @include Mixins.flex(row, flex-start, center);
  margin-bottom: 10px;
  &.avatar {
    color: var(--HeaderFooterText);
    &:hover {
      color: var(--FunkerRed);
    }
  }
  &.categories {
    margin-bottom: 10px;
    margin-left: 5px;
  }
  &.sidebar {
    background-color: var(--DropdownBackground);
    &:hover {
      background-color: var(--FunkerRed);
    }
    height: 30px;
    padding-left: 10px;
    margin-bottom: 0px;
  }
}

.dropdownItemIcon {
  &.avatar {
    margin-right: 8px;
    font-size: 0.75em;
    &.pro {
      color: var(--FunkerProGold);
    }
  }
  &.categories {
    margin-right: 4px;
    font-size: 0.625em;
  }
  &.sidebar {
    margin-right: 4px;
    font-size: 0.875em;
  }
}

.dropdownItemText {
  -webkit-font-smoothing: antialiased;
  &.avatar {
    font-size: 0.75em;
    font-weight: 700;
  }
  &.categories {
    font-size: 0.75em;
    font-weight: 700;
    color: var(--HeaderFooterText);
    &:hover {
      color: var(--FunkerRed);
    }
  }
  &.sidebar {
    font-size: 0.875em;
    font-weight: 700;
    color: var(--FontColorDarker);
  }
}
