@use "./Mixins";

.addArticleButtonContainer {
  position: fixed;
  bottom: 90px;
  right: 90px;
  z-index: 100;
}

.addArticleButton {
  @include Mixins.flex(row, center, center);
  width: 50px;
  height: 50px;
  border: 1px solid var(--FontColorDarker);
  border-radius: 50%;
  background-color: var(--SectionBackground);
  color: var(--FontColorDarker);
  font-size: 1.25em;
  font-weight: 700;
  box-shadow: 2px 2px 5px 0px var(--FunkerGrey);
  cursor: pointer;
  &:hover {
    background-color: var(--FunkerRed);
  }
}
